export default {
  loadMenus: (
    translate,
    {
      profileMetaInfo: { menu, requests, transactions, settings, riskManagement },
      userPermissions: { get: getUser } = {},
      clientPermissions: { get: getClient, getAssigned: getAssignedClients } = {},
      leadsPermissions: { get: getLeads, getAssigned: getAssignedLeads } = {},
      depositsPermissions: { get: getDeposits, getAssigned: getMyDeposits } = {},
      withdrawalsPermissions: { get: getWithdrawals, getAssigned: getMyWithdrawals } = {},
      internalTransfersPermissions: { get: getInternalTransfers, getAssigned: getMyTransfers } = {},
      requestsPermissions: { get: getRequests, getAssigned: getMyRequests } = {},
      creditPermissions: { get: getCredits, getAssigned: getMyCredits } = {},
      convertPermissions: { get: getConverts } = {},
      markupsPermissions: { get: getMarkups } = {},
      rolesPermissions: { get: getRoles } = {},
      AccTypesPermissions: { get: getAccTypes } = {},
      dictionariesPermissions: { get: getDictionaries } = {},
      feeGroupsPermissions: { get: getFeeGroup } = {},
      systemEmailsPermissions: { get: getSystemEmail } = {},
      emailConfigPermissions: { get: getEmailConfig } = {},
      teamsPermissions: { get: getTeams } = {},
      targetsPermissions: { get: getTargets } = {},
      symbolsPermissions: { get: getSymbols } = {},
      currencyPairsPermissions: { get: getCurrencyPair } = {},
      orderProfitPermissions: { get: getOrderProfit } = {},
      transactionProfitPermissions: { get: getTransactionProfit } = {},
      todosPermissions: { get: getTodos } = {},
      companyBanksPermissions: { get: getCompanyBanks } = {},
      userLogsPermissions: { get: getUserLogs } = {},
      conversionRatePermissions: { get: getConversionRate } = {},
      emailCampaignPermissions: { get: getEmailCampaign } = {},
      addressManagementPermissions: { get: getAddressManagement } = {},
      permissions: {
        reports: {
          get: getReports,
          getAssigned: getAssignedReports,
        } = {}
      } = {},
    }
  ) => [
    {
      menuItemLink: "/dashboard",
      menuItemName: translate("Dashboard"),
      menuItemIcon: "home",
      className: "",
      visibility: (menu || {}).dashboard,
    },
    {
      menuItemLink: "/clients",
      menuItemName: translate("Clients"),
      menuItemIcon: "users",
      className: `${!getClient && !getAssignedClients ? "d-none" : ""}`,
      visibility: (menu || {}).clients,
    },
    {
      menuItemLink: "/leads",
      menuItemName: translate("Leads"),
      menuItemIcon: "monitor",
      className: `${!getLeads && !getAssignedLeads ? "d-none" : ""}`,
      visibility: (menu || {}).leads,
    },
    {
      menuItemLink: "/assets",
      menuItemName: translate("Assets"),
      menuItemIcon: "dollar-sign",
      className: "",
      visibility: false,
    },
    {
      menuItemLink: "/reports",
      menuItemName: translate("Reports"),
      menuItemIcon: "book-open",
      className: `${!getReports && !getAssignedReports ? "d-none" : ""}`,
      visibility: (menu || {}).clients,
    },
    {
      menuItemLink: "/requsts",
      menuItemName: translate("Requests"),
      menuItemIcon: "send",
      className: "has-arrow",
      visibility: ((menu || {}).requests || getRequests),
      hasSubMenus: true,
      subMenus: [
        {
          menuItemLink: "/requests/accounts",
          menuItemName: translate("Accounts Request"),
          className: `${!(getRequests || getMyRequests) ? "d-none" : ""}`,
          visibility: (requests || {}).accountRequest,
        },
        {
          menuItemLink: "/requests/ib",
          menuItemName: translate("IB Request"),
          className: `${!(getRequests || getMyRequests) ? "d-none" : ""}`,
          visibility: (requests || {}).ibRequest,
        },
        {
          menuItemLink: "/requests/leverage",
          menuItemName: translate("Change Leverage"),
          className: `${!(getRequests || getMyRequests) ? "d-none" : ""}`,
          visibility: (requests || {}).changeLeverage,
        },
      ],
    },
    {
      menuItemLink: "/transactions",
      menuItemName: translate("Transactions"),
      menuItemIcon: "trending-up",
      className: "has-arrow",
      visibility: (menu || {}).transactions,
      hasSubMenus: true,
      subMenus: [
        {
          menuItemLink: "/transactions/deposit",
          menuItemName: translate("Deposit"),
          className: `${!(getDeposits || getMyDeposits) ? "d-none" : ""}`,
          visibility: (transactions || {}).deposit,
        },
        {
          menuItemLink: "/transactions/withdrawals",
          menuItemName: translate("Withdrawal"),
          className: `${!(getWithdrawals || getMyWithdrawals) ? "d-none" : ""}`,
          visibility: (transactions || {}).withdrawal,
        },
        {
          menuItemLink: "/transactions/internal-transfer",
          menuItemName: translate("Internal Transfer"),
          className: `${!(getInternalTransfers || getMyTransfers) ? "d-none" : ""}`,
          visibility: (transactions || {}).internalTransfer,
        },
        {
          menuItemLink: "/transactions/credit",
          menuItemName: translate("Credit"),
          className: `${!(getCredits || getMyCredits) ? "d-none" : ""}`,
          visibility: (transactions || {}).credit,
        },
        {
          menuItemLink: "/transactions/convert",
          menuItemName: translate("Convert"),
          className: "",
          visibility: (transactions || {}).convert,
        },
      ],
    },
    {
      menuItemLink: "/positions",
      menuItemName: translate("Positions"),
      menuItemIcon: "cast",
      className: "",
      visibility: false,
    },
    {
      menuItemLink: "/calendar",
      menuItemName: translate("Calendar"),
      menuItemIcon: "calendar",
      className: `${!getTodos ? "d-none" : ""}`,
      visibility: true,
    },
    {
      menuItemLink: "/marketing",
      menuItemName: translate("Marketing"),
      menuItemIcon: "radio",
      className: "has-arrow",
      visibility: (menu || {}).marketing,
      hasSubMenus: true,
      subMenus: [
        {
          menuItemLink: "/email-campaigns/",
          menuItemName: translate("Campaigns"),
          className: `${!getEmailCampaign ? "d-none" : ""}`,
          visibility: (settings || {}).emailCampaign,
        },
        {
          menuItemLink: "/email-campaigns/templates",
          menuItemName: translate("Campaign Templates"),
          className: `${!getEmailCampaign ? "d-none" : ""}`,
          visibility: (settings || {}).emailCampaign,
        },
        {
          menuItemLink: "/email-campaigns/unsubscribers",
          menuItemName: translate("Campaign Unsubscribers"),
          className: `${!getEmailCampaign ? "d-none" : ""}`,
          visibility: (settings || {}).emailCampaign,
        },
        {
          menuItemLink: "/email-campaigns/links",
          menuItemName: translate("Campaign Links"),
          className: "",
          visibility: true,
        },
      ],
    },
    {
      menuItemLink: "/settings",
      menuItemName: translate("Settings"),
      menuItemIcon: "tool",
      className: "has-arrow",
      visibility: (menu || {}).settings,
      hasSubMenus: true,
      subMenus: [
        {
          menuItemLink: "/dictionaries",
          menuItemName: translate("Dictionaries"),
          className: `${!getDictionaries ? "d-none" : ""}`,
          visibility: (settings || {}).dictionaries,
        },
        {
          menuItemLink: "/users",
          menuItemName: translate("Users"),
          className: `${!getUser ? "d-none" : ""}`,
          visibility: (settings || {}).users,
        },
        {
          menuItemLink: "/account-types",
          menuItemName: translate("Account Types"),
          className: `${!getAccTypes ? "d-none" : ""}`,
          visibility: (settings || {}).accountTypes,
        },
        {
          menuItemLink: "/roles",
          menuItemName: translate("Roles"),
          className: `${!getRoles ? "d-none" : ""}`,
          visibility: (settings || {}).roles,
        },
        {
          menuItemLink: "/system-emails",
          menuItemName: translate("System Emails"),
          className: `${!getSystemEmail ? "d-none" : ""}`,
          visibility: (settings || {}).systemEmails,
        },
        
        {
          menuItemLink: "/email-config",
          menuItemName: translate("Email Configurations"),
          className: `${!getEmailConfig ? "d-none" : ""}`,
          visibility: (settings || {}).emailConfig,
        },
        {
          menuItemLink: "/banks",
          menuItemName: translate("Bank Accounts"),
          className: `${!getCompanyBanks ? "d-none" : ""}`,
          visibility: (settings || {}).companyBanks,
        },
        {
          menuItemLink: "/user-logs",
          menuItemName: translate("User Logs"),
          className: `${!getUserLogs ? "d-none" : ""}`,
          visibility: (settings || {}).userLogs,
        },
        {
          menuItemLink: "/teams",
          menuItemName: translate("Teams"),
          className: `${!getTeams ? "d-none" : ""}`,
          visibility: (settings || {}).teams,
        },
        {
          menuItemLink: "/targets",
          menuItemName: translate("Targets"),
          className: `${!getTargets ? "d-none" : ""}`,
          visibility: (settings || {}).targets,
        },
        // {
        //   menuItemLink: "/banners",
        //   menuItemName: translate("Banners"),
        //   className: "",
        //   visibility: (settings || {}).banners,
        // },
        {
          menuItemLink: "/assets",
          menuItemName: translate("Symbols"),
          className: `${!getSymbols ? "d-none" : ""}`,
          visibility: (settings || {}).symbols,
        },
        {
          menuItemLink: "/currency-pairs",
          menuItemName: translate("Currency Pairs"),
          className: `${!getCurrencyPair ? "d-none" : ""}`,
          visibility: (settings || {}).currencyPairs,
        },
        {
          menuItemLink: "/conversion-rates",
          menuItemName: translate("Conversion Rates"),
          className: `${!getConversionRate ? "d-none" : ""}`,
          visibility: (settings || {}).conversionRates,
        },
        {
          menuItemLink: "/predefined-agreement",
          menuItemName: translate("Predefined Agreement"),
          className: `${""}`,
          visibility: true,
        },
        {
          menuItemLink: "/address-management",
          menuItemName: translate("Address Management"),
          className: `${!getAddressManagement ? "d-none" : ""}`,
          visibility: (settings || {}).addressManagement,
        },
      ],
    },
    {
      menuItemLink: "/risk-management",
      menuItemName: translate("Risk Management"),
      menuItemIcon: "alert-circle",
      className: "has-arrow",
      visibility: (menu || {}).riskManagement,
      hasSubMenus: true,
      subMenus: [
        {
          menuItemLink: "/fee-groups",
          menuItemName: translate("Trading Fee Groups"),
          className: `${!getFeeGroup ? "d-none" : ""}`,
          visibility: (riskManagement || {}).dictionaries,
        },
        {
          menuItemLink: "/transaction-fee-groups",
          menuItemName: translate("Transaction Fee Groups"),
          className: "",
          visibility: (riskManagement || {}).transactionFeeGroups,
        },
        {
          menuItemLink: "/markups",
          menuItemName: translate("Markups"),
          className: `${!getMarkups ? "d-none" : ""}`,
          visibility: (riskManagement || {}).markups,
        },
        {
          menuItemLink: "/orders-profit",
          menuItemName: translate("Order Profit"),
          className: `${!getOrderProfit ? "d-none" : ""}`,
          visibility: (riskManagement || {}).orderProfit,
        },
        {
          menuItemLink: "/transactions-profit",
          menuItemName: translate("Exchange Balance"),
          className: `${!getTransactionProfit ? "d-none" : ""}`,
          visibility: (riskManagement || {}).exchangeBalance,
        },
      ],
    },
  ],
};
