export const FETCH_DEPOSITS_START = "FETCH_DEPOSITS_START";
export const FETCH_DEPOSITS_SUCCESS = "FETCH_DEPOSITS_SUCCESS";
export const ADD_DEPOSIT_START = "ADD_DEPOSIT_START";
export const ADD_FORM_DEPOSIT_START = "ADD_FORM_DEPOSIT_START";
export const ADD_DEPOSIT_SUCCESS = "ADD_DEPOSIT_SUCCESS";
export const DEPOSIT_ERROR = "DEPOSIT_ERROR";
export const MODAL_CLEAR = "MODAL_CLEAR";
export const DEPOSIT_APPROVE = "DEPOSIT_APPROVE";
export const DEPOSIT_APPROVE_SUCCESS = "DEPOSIT_APPROVE_SUCCESS";
export const DEPOSIT_APPROVE_FAIL = "DEPOSIT_APPROVE_FAIL";
export const DEPOSIT_REJECT = "DEPOSIT_REJECT";
export const DEPOSIT_STATUS_CHANGE_SUCCESS = "DEPOSIT_STATUS_CHANGE_SUCCESS";
export const DEPOSIT_STATUS_CHANGE_FAIL = "DEPOSIT_STATUS_CHANGE_FAIL";

// fetch client deposits
export const FETCH_CLIENT_DEPOSITS_REQUESTED = "FETCH_CLIENT_DEPOSITS_REQUESTED";
export const FETCH_CLIENT_DEPOSITS_SUCCESS = "FETCH_CLIENT_DEPOSITS_SUCCESS";
export const FETCH_CLIENT_DEPOSITS_FAIL = "FETCH_CLIENT_DEPOSITS_FAIL";
export const ERROR_CLEAR = "ERROR_CLEAR";