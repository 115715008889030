export const FETCH_WITHDRAWALS_START = "fETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const MAKE_WITHDRAWAL_START = "MAKE_WITHDRAWAL_START";
export const MAKE_WITHDRAWAL_SUCCESS = "MAKE_WITHDRWAL_SUCCESS";
export const WITHDRAWAL_ERROR = "WITHDRAWAL_ERROR";
export const MODAL_CLEAR = "MODAL_CLEAR";
export const WITHDRAW_APPROVE_START = "WITHDRAW_APPROVE_START";
export const WITHDRAW_APPROVE_SUCCESS = "WITHDRAW_APPROVE_SUCCESS";
export const WITHDRAW_APPROVE_FAILURE = "WITHDRAW_APPROVE_FAILURE";
export const WITHDRAW_REJECT = "WITHDRAW_REJECT";
export const WITHDRAW_STATUS_CHANGE_SUCCESS = "WITHDRAW_STATUS_CHANGE_SUCCESS";
export const WITHDRAW_STATUS_CHANGE_FAIL = "WITHDRAW_STATUS_CHANGE_FAIL";
export const WITHDRAWAL_ERROR_CLEAR = "WITHDRAWAL_ERROR_CLEAR";

// fetch client withdrawals
export const FETCH_CLIENT_WITHDRAWALS_REQUESTED = "FETCH_CLIENT_WITHDRAWALS_REQUESTED";
export const FETCH_CLIENT_WITHDRAWALS_SUCCESS = "FETCH_CLIENT_WITHDRAWALS_SUCCESS";
export const FETCH_CLIENT_WITHDRAWALS_FAIL = "FETCH_CLIENT_WITHDRAWALS_FAIL";

export const ERROR_CLEAR = "ERROR_CLEAR";