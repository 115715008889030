import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { 
  Container, 
  Card, 
  Button
} from "react-bootstrap";
import {
  Table, Thead, Tbody, Tr, Th
} from "react-super-responsive-table";
import { AvForm } from "availity-reactstrap-validation";

import { fetchReferrals } from "store/client/actions";
import { fetchAgreements } from "store/actions";

import RecursiveTableRows from "./RecursiveTableRows";
import Select from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CustomSelect from "components/Common/CustomSelect";
import * as XLSX from "xlsx";

const Referral = ({ t }) => {
  const dispatch = useDispatch();

  const { _id, referrals } = useSelector((state) => state.clientReducer?.clientDetails);
  const { agreements } = useSelector((state) => state.ibAgreements);

  const [selectedAgreement, setSelectedAgreement] = useState(null);

  const getTotalNestedChildren = (arr) => {
    let total = 0;
  
    const countNestedChildren = (data) => {
      if (data.childs && data.childs.length > 0) {
        total += data.childs.length;
        data.childs.forEach(child => countNestedChildren(child));
      }
    };
  
    arr?.forEach(item => countNestedChildren(item));
  
    return total;
  };

  const parentFirstName = referrals?.[0]?.firstName;
  const parentLastName = referrals?.[0]?.lastName;

  const filteredReferrals = selectedAgreement ?
    referrals?.[0]?.childs?.filter(referral => ((referral.fx.agrementId === selectedAgreement) || referral.fx.agrementId === undefined)) :
    referrals?.[0]?.childs;
  
  const childData = [];
  
  (filteredReferrals || []).forEach(child => {
    const data = getChildData(child, parentFirstName, parentLastName, 0);
    childData.push(data);
  });

  function getChildData(child, parentFirstName, parentLastName, level) {
    const data = {
      "Name": `${child?.firstName || ""} ${child?.lastName || ""}`,
      "Parent Name": `${parentFirstName || ""} ${parentLastName || ""}`,
      "Client Type": child?.fx?.isIb ? "Sub IB" : "Client",
      "Live Accounts": child?.fx?.liveAcc?.length > 0 ? child?.fx?.liveAcc.join(", ") : "No Accounts",
      "Demo Accounts": child?.fx?.demoAcc?.length > 0 ? child?.fx?.demoAcc.join(", ") : "No Accounts",
      "Indentation Level": level
    };
  
    if (child.childs && child.childs.length > 0) {
      data["Childs"] = child.childs.map(subchild => getChildData(subchild, child.firstName, child.lastName, level + 1)); // Increase indentation level for nested child
    }
  
    return data;
  }
  
  const downloadRefferalsExcel = () => {
    const nestedChildData = [];
    
    nestedChildData.push([
      "Name",
      "Parent Name",
      "Client Type",
      "Live Accounts",
      "Demo Accounts"
    ]);
    
    function populateNestedChildData(data) {
      const index = nestedChildData.length - 1;
      const insertIndex = index < 0 ? 0 : index + 1; 
      
      nestedChildData.splice(insertIndex, 0, [
        ...Array(data["Indentation Level"]).fill(""),
        data.Name,
        data["Parent Name"],
        data["Client Type"],
        data["Live Accounts"],
        data["Demo Accounts"]
      ]);
      
      if (data["Childs"]) {
        data["Childs"].forEach(child => populateNestedChildData(child));
        
        nestedChildData.splice(insertIndex + 1, 0, [
          ...Array(data["Indentation Level"]).fill(""),
          "Child",
          "Name",
          "Parent Name",
          "Client Type",
          "Live Accounts",
          "Demo Accounts"
        ]);
      }
    }
    
    childData.forEach(child => populateNestedChildData(child));
  
    const ws = XLSX.utils.aoa_to_sheet([[]]);
    ws["!cols"] = [{ width: 10 }, { width: 25 }, { width: 25 }, { width: 25 }, { width: 25 }, { width: 25 }, { width: 25 }];
    XLSX.utils.sheet_add_aoa(ws, nestedChildData, { origin: "A2" });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "referrals.xlsx");
  };

  useEffect(() => {
    if (_id) {
      _id && dispatch(fetchReferrals({
        type: "live",
        clientId: _id,
        selectedAgreement
      }));
      dispatch(fetchAgreements({ customerId: _id }));
    }
  }, [_id, selectedAgreement]);
  return (
    <Container fluid className="gx-4">
      <AvForm className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <div className="row m-2">
            <label className="color-primary">{t("Select Agreement")}</label>
            <CustomSelect
              className="w-full"
              name="agreement"
              onChange={(e) => { setSelectedAgreement(e.value) }}
              value={
                selectedAgreement === null ? "" : undefined
              }
              options={agreements?.map((agreement) => {
                return {
                  label: `${agreement?.title} (${agreement?.members
                    ?.map(
                      (member) =>
                        ( `${member?.customerId?.firstName} - ${member?.customerId?.lastName}`)
                    )
                    .join(", ")})`, // Joining the array elements into a string
                  value: agreement._id,
                };
              })}
            />
          </div>
          <div className="row col-md-auto align-content-end m-2" >
            <Button onClick={()=>{
              setSelectedAgreement(null);
            }}>
              {t("Clear")}
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="color-primary mb-0">{`Total Clients: ${getTotalNestedChildren(referrals)}`}</h5>
          <div className="row col-md-auto align-content-end" style={{margin : "0.5rem"}}>
            <Button onClick={downloadRefferalsExcel}>Download Referrals</Button>
          </div>
        </div>
      </AvForm>
      
      <Card className="p-3 mt-4">
        {referrals &&
          <Table className="table table-hover text-center">
            <Thead>
              <Tr >
                <Th className="py-2 color-primary text-start">{t("")}</Th>
                <Th className="py-2 color-primary text-start">{t("Name")}</Th>
                <Th className="py-2 color-primary text-start">{t("Parent Name")}</Th>
                <Th className="py-2 color-primary">{t("Client Type")}</Th>
                <Th className="py-2 color-primary">{t("Accounts")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <RecursiveTableRows data={referrals[0]} filter={selectedAgreement} level={0} />
            </Tbody>
          </Table>}
      </Card>
    </Container >
  );
};

export default withTranslation()(Referral);
