// fetch forex WITHDRAWAL
export const FETCH_FOREX_WITHDRAWALS_REQUESTED = "FETCH_FOREX_WITHDRAWALS_REQUESTED";
export const FETCH_FOREX_WITHDRAWALS_SUCCESS = "FETCH_FOREX_WITHDRAWALS_SUCCESS";
export const FETCH_FOREX_WITHDRAWALS_FAIL = "FETCH_FOREX_WITHDRAWALS_FAIL";

export const ADD_FOREX_WITHDRAWAL_REQUESTED = "ADD_FOREX_WITHDRAWAL_REQUESTED";
export const ADD_FOREX_WITHDRAWAL_SUCCESS = "ADD_FOREX_WITHDRAWAL_SUCCESS";
export const ADD_FOREX_WITHDRAWAL_FAIL = "ADD_FOREX_WITHDRAWAL_FAIL";
export const ADD_FOREX_WITHDRAWAL_CLEAR = "ADD_FOREX_WITHDRAWAL_CLEAR";
export const ADD_FOREX_WITHDRAWAL_ERROR_CLEAR = "ADD_FOREX_WITHDRAWAL_ERROR_CLEAR";
export const APPROVE_FOREX_WITHDRAWAL = "APPROVE_FOREX_WITHDRAWAL";
export const APPROVE_FOREX_WITHDRAWAL_SUCCESS = "APPROVE_FOREX_WITHDRAWAL_SUCCESS";
export const APPROVE_FOREX_WITHDRAWAL_FAIL = "APPROVE_FOREX_WITHDRAWAL_FAIL";
export const REJECT_FOREX_WITHDRAWAL = "REJECT_FOREX_WITHDRAWAL";
export const REJECT_FOREX_WITHDRAWAL_SUCCESS = "REJECT_FOREX_WITHDRAWAL_SUCCESS";