import {
  Button,
  Modal, ModalBody, ModalHeader 
} from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  createSharedIbAgreement, fetchAgreements, fetchPredefinedAgreements 
} from "store/actions";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import AgreementDetails from "./AgreementDetails";

function AddSharedIbModalV2({ show, toggle, t }) {
  const [filteredAgreements, setFilteredAgreements] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const dispatch = useDispatch();
  const { layoutMode } = useSelector(state => state.Layout);
  const { accountTypes = [] } = useSelector((state) => state?.tradingAccountReducer);
  const clientDetails = useSelector((state) => state.clientReducer?.clientDetails);
  const { agreements } = useSelector(state => state.predefinedIbAgrements);
  const { agreements: originalAgreements, submitting, clearingCounter } = useSelector((state) => state.ibAgreements);
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#495057",
      padding: 0,
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#19283B",
          border: 0,
          boxShadow: "0 0.125rem 0.25rem #0B182F",
          color: "#adb5bd",
          height: "100%",
        };
      }
      return {
        ...provided,
        height: "100%",
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };

  const loadPredefinedAgrements = (page, limit) => {
    dispatch(fetchPredefinedAgreements({
      page,
      limit
    }));
  };

  useEffect(()=>{
    loadPredefinedAgrements(1, 100);
  }, [show]);

  useEffect(() => {
    if (clientDetails)
      if (clientDetails.parentId && clientDetails.parentAgrement) {
        const agrement = agreements.docs.filter(ag => ag.levels === clientDetails.parentAgrement.members.length + 1);
        setFilteredAgreements(agrement);
        if (selectedAgreement)
          adjustAgrementCustomerIds(selectedAgreement, clientDetails.parentAgrement);
      } else {
        const agrement = agreements.docs.filter(ag => ag.levels === 1);
        setFilteredAgreements(agrement);
        if (selectedAgreement)
          adjustAgrementCustomerIds(selectedAgreement, { members: [] });
      }
  }, [clientDetails, agreements, selectedAgreement, clientDetails]);

  function adjustAgrementCustomerIds(predefined, original) {
    predefined?.members?.sort((a, b) => a.level - b.level);
    original?.members?.sort((a, b) => a.level - b.level);
    const predefinedMembers = predefined?.members;
    const originalMembers = original?.members;
    if (originalMembers && originalMembers.length === 0){
      predefinedMembers[0].customerId = {
        "fx": clientDetails.fx,
        "_id": clientDetails._id,
        "firstName": clientDetails.firstName,
        "lastName": clientDetails.lastName
      };
    }
    for (let i = 0; i < predefinedMembers.length; i++) {
      if (i === predefinedMembers.length - 1){
        predefinedMembers[i].customerId = {
          "fx": clientDetails.fx,
          "_id": clientDetails._id,
          "firstName": clientDetails.firstName,
          "lastName": clientDetails.lastName
        };
      } else {
        if (originalMembers && originalMembers.length > 0){
          predefinedMembers[i].customerId = originalMembers[i].customerId;
        }
      }
    }
  }

  const addAgrement = () => {
    const members = [];
    for (let i = 0; i < selectedAgreement.members.length; i++) {
      const member = selectedAgreement.members[i];
      members.push({
        customerId: member.customerId._id,
        level: member.level,
        values: member.values
      });
    }
    const agrement = {
      title: selectedAgreement.title,
      totals: selectedAgreement.totals,
      members
    };    
    dispatch(
      createSharedIbAgreement(agrement)
    );
  };

  // useEffect(()=>{
  //   if (clearingCounter > 0){
  //     toggle();
  //   }
  // }, [clearingCounter]);

  useEffect(()=>{
    if (show === false){
      setSelectedAgreement(null);
    }
  }, [show]);

  return ( <>
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Select Agreement")}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <Select
          styles={customStyles}
          name="predefinedAgreements"
          options={filteredAgreements.map(agreement => {
            return {
              label: agreement.title,
              value: agreement,
            };
          }
          )}
          onChange={(e) => setSelectedAgreement(e.value)}
        />
        <div className="mt-3">
          {selectedAgreement && <AgreementDetails agreement={selectedAgreement} accountTypes={accountTypes} />}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Button onClick={addAgrement} disabled={submitting || selectedAgreement === null}>
            {t("Add")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  </>);
}

export default AddSharedIbModalV2;