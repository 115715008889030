import {
  FETCH_PREDEFINED_IB_AGREEMENTS_START,
  FETCH_PREDEFINED_IB_AGREEMENTS_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const fetchPredefinedAgreements = (data) => {
  return {
    type: FETCH_PREDEFINED_IB_AGREEMENTS_START,
    payload: data
  };
};
export const fetchPredefinedAgreementsSuccess = (data) => {
  return {
    type: FETCH_PREDEFINED_IB_AGREEMENTS_SUCCESS,
    payload: data
  };
};
export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error
  };
};