import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { addConversionRates } from "store/actions";
import AddSharedIbModal from "pages/ClientDetail/Partnership/AddSharedIbModal";
import useModal from "hooks/useModal";

function AddPredefinedAgreement(props) {
  const [addModal, setAddTeamModal] = useState(false);
  const [name, setName] = useState(null);
  const [level, setLevel] = useState(null);
  const [showSharedIB, toggleSharedIB] = useModal();
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const { products } = useSelector((state) => state.ibAgreements);
  const { _id } = useSelector((state) => state.clientReducer?.clientDetails);
  const { clearingCounter } = useSelector((state) => state.ibAgreements);
  const markups = useSelector((state) => state.dictionaryReducer.markups);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleAddModal = () => {
    setAddTeamModal(!addModal);
  };

  useEffect(() => {
    if (clearingCounter > 0 && addModal && showSharedIB) {
      setAddTeamModal(false);
      toggleSharedIB();
    }
  }, [clearingCounter]);

  const create = true;
  return (
    <React.Fragment>
      <Button color="primary" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}>
        <i className="bx bx-plus me-1"></i>  {t("Add New Predefined Structure")}
      </Button>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {t("Structure")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              setName(v.name);
              setLevel(v.level);
              toggleSharedIB();
            }}
          >
            <div className="mb-3">
              <AvField
                name="name"
                label={t("Name")}
                placeholder={t("Enter Name")}
                type="text"
                errorMessage={t("Enter Name")}
                validate={{ required: { value: true } }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="level"
                label={t("Level")}
                placeholder={t("Enter Level")}
                type="number"
                errorMessage={t("Enter Level")}
                validate={{
                  required: { value: true },
                  min: { value: 0 }
                }}
              />
            </div>
            <div className="text-center d-flex align-items-center justify-content-center">
              <Button type="click" color="primary" className="m-1" onClick={() => {
                toggleAddModal();
              }}>
                {t("Back")}
              </Button>
              <Button type="submit" color="primary" className="m-1">
                {t("Continue")}
              </Button>
            </div>
          </AvForm>
          {props.addError && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {/* {props.addErrorDetails} */}
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
      {showSharedIB && <AddSharedIbModal
        show={showSharedIB}
        toggle={toggleSharedIB}
        accountTypes={accountTypes}
        products={products}
        clientId={_id}
        markups={markups}
        predefinedName={name}
        predefinedLevel={level}
        predefinedStructure={true}
      />}
    </React.Fragment>
  );
}

export default (AddPredefinedAgreement);
