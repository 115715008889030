import {
  API_ERROR, FETCH_PREDEFINED_IB_AGREEMENTS_START, FETCH_PREDEFINED_IB_AGREEMENTS_SUCCESS,
} from "./actionTypes";

const initalState = {
  submitting: false,
  deleting: false,
  error: "",
  products: [],
  agreements: {
    docs: [],
    page: 1,
    limit: 10,
    loading: false
  },
  successMessage: ""
};
const ibAgreementReducer = (state = initalState, action) => {
  switch (action.type) {
    case FETCH_PREDEFINED_IB_AGREEMENTS_START:
      state = {
        ...state,
        agreements: {
          ...state.agreements,
          loading: true
        },
        error: ""
      };
      break;
    case FETCH_PREDEFINED_IB_AGREEMENTS_SUCCESS:
      state = {
        ...state,
        agreements: {
          ...state.agreements,
          ...action.payload,
          loading: false 
        },
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        submitting: false,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default ibAgreementReducer;