import * as axioHelper from "./api_helper";
import qs from "qs";

export async function fetchPredefinedIbAgreements(payload) {
  try {
    const result = await axioHelper.get(`/predefined-ibagrements/?${qs.stringify(payload)}`);
    return result;
  } catch (error) {
    throw new Error("Error while fetching IB agreements.");
  }
}