import logo from "assets/images/brands/tradefx-logo.png";

export const clientName = "Tradefx Pte.Ltd";
export const developedBy = "Exinitic";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "Tradefx Pte.Ltd";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;